export default [
  {
    path: '/update',
    name: 'update',
    component: () => import('@/features/pages/update.vue'),
    meta: {
      title: '系统维护中',
    },
  },
];
