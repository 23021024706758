import locationHrefReplaceSearchKey from '@/features/common/utils/locationHrefReplaceSearchKey';
import cookie from 'js-cookie';

const useSession = false;

const tokenKey = '_tk';
let tokenTmp =
  (useSession ? sessionStorage.getItem(tokenKey) : cookie.get(tokenKey)) || '';
export const tokenStorage = (val = null) => {
  if (val || val !== null) {
    tokenTmp = val || '';
    if (!tokenTmp) {
      useSession
        ? sessionStorage.removeItem(tokenKey)
        : cookie.remove(tokenKey);
    } else {
      useSession
        ? sessionStorage.setItem(tokenKey, tokenTmp)
        : cookie.set(tokenKey, tokenTmp, {
            expires: 1,
            domain: location.hostname.replace(/.*(.jiakao520.*)/, '$1'),
          });
    }
  }
  return tokenTmp;
};

export const [, urlToken] = location.href.match(/_tk=(\w+)/) || [];
if (urlToken) {
  tokenStorage(urlToken);

  locationHrefReplaceSearchKey('_tk');
}
