import { Dialog, ImagePreview, Toast, Notify, Lazyload } from 'vant';

const components = [Dialog, ImagePreview, Toast, Notify, Lazyload];
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';

const _m = 'clear';
const _original = Toast.prototype[_m];
Toast.prototype[_m] = async function (location) {
  await new Promise((resolve) => setTimeout(resolve, 1));
  return _original.call(this, location);
};

const ipc = {
  add(el) {
    if (!el.ipcOnClick) {
      el.ipcOnClick = (pe) => {
        const { target } = pe;
        if (!/img/i.test(target.tagName)) return;

        const images = Array.from(el.querySelectorAll('img'))
          .map((i) => i.src)
          .filter((i) => i);

        const clickIdx = images.indexOf(target.src);

        ImagePreview({
          images,
          startPosition: clickIdx > -1 ? clickIdx : 0,
          closeable: true,
        });
      };
    }

    el.addEventListener('click', el.ipcOnClick);
  },
  remove(el) {
    el.removeEventListener('click', el.ipcOnClick);
  },
};

const directiveIpc = {
  beforeMount(el) {
    ipc.add(el);
  },
  beforeUnmount(el) {
    ipc.remove(el);
  },
  // vue2
  bind(el) {
    ipc.add(el);
  },
  unbind(el) {
    ipc.add(el);
  },
};

export default {
  install(app) {
    app.directive('ipc', directiveIpc);
    components.forEach((i) => app.use(i));
  },
};
