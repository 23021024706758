import loadjs from '@/features/common/libs/load';
import { wxParams } from './config';
import throttlePromise from '../common/utils/throttlePromise';
import { isWeixin } from '@/features/weixin/utils';

if (isWeixin) {
  loadjs('https://res.wx.qq.com/open/js/jweixin-1.6.0.js', 'jweixin');
}

const signature = async (jsApiList = null) => {
  if (!isWeixin) throw '';
  await loadjs.ready('jweixin');

  if (typeof wxParams.signatureRequestPromise !== 'function')
    throw new Error(`缺失签名请求函数`);

  const url = location.href.replace(location.hash, '');

  return configThrottlePromise(jsApiList || wxParams.jsApiList, url);
};
export default signature;

let lastKey = '';
const config = async (jsApiList, url) => {
  const { wx } = window;

  const key = JSON.stringify({ jsApiList, url });
  if (key === lastKey) return wx;
  lastKey = key;

  const res = await wxParams.signatureRequestPromise(jsApiList.join(','), url);
  return new Promise((resolve, reject) => {
    wx.config({
      debug: false,
      appId: res.appId,
      timestamp: res.timestamp,
      nonceStr: res.nonceStr,
      signature: res.signature,
      jsApiList: res.jsApiList,
      openTagList: wxParams.openTagList,
    });
    wx.ready(() => resolve(wx));
    wx.error((e) => reject(e?.errMsg || e));
  });
};
const configThrottlePromise = throttlePromise(config);

export const getLocation = async (options) => {
  await signature(['getLocation']);
  return new Promise((resolve, reject) => {
    const { wx } = window;
    wx.getLocation({
      type: 'wgs84',
      ...options,
      success: resolve,
      fail: reject,
      cancel: reject,
    });
  });
};

export const updateAppMessageShareData = (options) =>
  new Promise((resolve, reject) => {
    if (!isWeixin) return;
    const { wx } = window;
    wx.updateAppMessageShareData({
      title: '',
      desc: '',
      link: '',
      imgUrl: '',
      ...options,
      success: resolve,
      fail: reject,
      cancel: reject,
    });
  });
export const updateTimelineShareData = (options) =>
  new Promise((resolve, reject) => {
    if (!isWeixin) return;
    const { wx } = window;
    wx.updateTimelineShareData({
      title: '',
      link: '',
      imgUrl: '',
      ...options,
      success: resolve,
      fail: reject,
      cancel: reject,
    });
  });

let updateShareLastArgs = '';
export const updateShare = async (
  title,
  desc = '',
  imgUrl = '',
  link = location.href
) => {
  if (!isWeixin) return;
  if (typeof title === 'object') {
    link = title.link;
    imgUrl = title.imgUrl;
    desc = title.desc;
    title = title.title;
  }
  const lastArgs = [title, desc, imgUrl, link].join('');
  if (updateShareLastArgs === lastArgs) return;
  updateShareLastArgs = lastArgs;
  await signature();
  return Promise.all([
    updateAppMessageShareData({ title, desc, imgUrl, link }),
    updateTimelineShareData({ title, imgUrl, link }),
  ]);
};

/**
 *    let message = e?.errMsg;
 *    if (message.match(/cancel/i)) message = '用户取消支付';
 *    if (message.match(/fail/i)) message = '支付失败';
 * @param config
 * @returns {Promise<unknown>}
 */
export const chooseWXPay = async (config) => {
  if (!isWeixin) throw '';
  const { wx } = window;
  await signature();
  return new Promise((resolve, reject) => {
    wx.chooseWXPay({
      timestamp: config.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      nonceStr: config.nonceStr, // 支付签名随机串，不长于 32 位
      package: config.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
      signType: config.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      paySign: config.paySign, // 支付签名
      success: resolve,
      cancel: reject,
      fail: reject,
    });
  });
};
