import loadjs from '@/features/common/libs/load';

loadjs(
  'https://cdn.jsdelivr.net/npm/long-press-event@2.4.4/dist/long-press-event.min.js',
  'LongPressEvent'
);

/**
 * https://github.com/john-doherty/long-press-event
 *   data-long-press-delay="1000"
 *   @long-press="$emit('remove', product)"
 */
