import throttle from 'lodash/throttle';

export const config = {
  defaultTitle: '',
};

export default throttle((title = '') => {
  if (title) {
    document.title = title + ' ' + config.defaultTitle;
    return;
  }
  document.title = config.defaultTitle;
}, 100);
