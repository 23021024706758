import loadjs from '@/features/common/libs/load';
import jsonp from '@/features/common/utils/jsonp';
import { serviceEnv } from '@/config/env';

export const config = {
  key: {
    release: 'I7RBZ-BJNCP-ZRMDE-LYL27-Q6RV5-H4BCX',
    test: '6NTBZ-SVYKP-EYVDB-VICFZ-HFFPT-6LFCL',
    // test: 'TKUBZ-D24AF-GJ4JY-JDVM2-IBYKK-KEBCU',
  }[serviceEnv],
};

let geolocationSingleton;
// https://lbs.qq.com/webApi/component/componentGuide/componentGeolocation
export const componentGeolocation = async (referer = location.hostname) => {
  if (geolocationSingleton) return geolocationSingleton;
  const { key } = config;
  if (!key || !referer) throw new Error('定位缺失key');
  const type = 1;
  if (type === 1) {
    await loadjs(
      'https://mapapi.qq.com/web/mapComponents/geoLocation/v/geolocation.min.js'
    );
    const { qq } = window;
    geolocationSingleton = new qq.maps.Geolocation(key, referer);
  } else {
    await loadjs(
      `https://apis.map.qq.com/tools/geolocation/min?key=${key}&referer=${referer}`
    );
    const { qq } = window;
    geolocationSingleton = new qq.maps.Geolocation();
  }
  return geolocationSingleton;
};

/**
 *
 * @param {{timeout: number}} options timeout:ms
 * @returns {Promise<unknown>}
 */
export const geolocationGetLocation = async (options = {}) => {
  const geolocation = await componentGeolocation();
  return new Promise((resolve, reject) =>
    geolocation.getLocation(resolve, reject, { ...options })
  );
};
export const geolocationGetIpLocation = async () => {
  const geolocation = await componentGeolocation();
  return new Promise((resolve, reject) =>
    geolocation.getIpLocation(resolve, reject)
  );
};

let _webServiceIp = null;
export const webServiceIp = async () => {
  if (_webServiceIp) return _webServiceIp;
  const { key } = config;
  const url = new URL('https://apis.map.qq.com/ws/location/v1/ip');
  url.searchParams.append('key', key);
  url.searchParams.append('output', 'jsonp');
  url.searchParams.append('callback', '_qqMap');
  _webServiceIp = (await jsonp(url, '_qqMap')).result;
  return _webServiceIp;
};

export const webServiceGcoder = (location) => {
  if (Array.isArray(location)) location = location.join();
  const { key } = config;
  const url = new URL('https://apis.map.qq.com/ws/location/v1');
  url.searchParams.append('key', key);
  url.searchParams.append('location', location);
  url.searchParams.append('output', 'jsonp');
  url.searchParams.append('callback', '_qqMap');
  return jsonp(url, '_qqMap').then((res) => res.result);
};
