import load from '@/features/common/libs/load';

/*
https://github.com/Tencent/vConsole
https://cdn.jsdelivr.net/npm/vconsole@3.14.7/dist/vconsole.min.js 以前的
*/
export default async (...args) => {
  await load('https://unpkg.com/vconsole@3.14.7/dist/vconsole.min.js');
  const { VConsole } = window;

  if (!VConsole) throw new Error('libs not vconsole');

  return new VConsole(...args);
};
