const throttleQueue = {};
export default (promiseFunction) =>
  (...args) =>
    new Promise((resolve, reject) => {
      const key = (promiseFunction.toString() + JSON.stringify(args)).replace(
        /[[\]{}:" \n;()/.<>=,;+-]/g,
        ''
      );
      if (!key) throw new Error('throttlePromise key fail');
      if (throttleQueue[key]) {
        throttleQueue[key].resolves.push(resolve);
        throttleQueue[key].rejects.push(reject);
        return;
      }
      throttleQueue[key] = { resolves: [resolve], rejects: [reject] };
      promiseFunction(...args)
        .then((res) => {
          if (!throttleQueue[key]) return;
          throttleQueue[key].resolves.forEach((i) => i(res));
          delete throttleQueue[key];
        })
        .catch((res) => {
          if (!throttleQueue[key]) return;
          throttleQueue[key].rejects.forEach((i) => i(res));
          delete throttleQueue[key];
        });
    });
