import throttlePromise from '@/features/common/utils/throttlePromise';

let cache = {};
export const jsonp = (url, callbackName = '_cbName') =>
  new Promise((resolve, reject) => {
    let s = document.querySelector(`[src="${url}"]`);
    if (s && s.data) {
      resolve(s.data);
      return;
    }
    if (cache[url]) {
      resolve(cache[url]);
      return;
    }
    window[callbackName] = (data) => {
      s.data = data;
      cache[url] = data;
      resolve(data);
    };
    s = document.createElement('script');
    s.src = url;
    s.onload = () => {
      delete window[callbackName];
      s.remove();
    };
    s.onerror = () => {
      delete window[callbackName];
      s.remove();
      reject();
    };
    document.head.appendChild(s);
  });

export default throttlePromise(jsonp);
