import { tokenStorage, urlToken } from '@/features/auth/token';
import weixinAuthorize, {
  hasCodeAndState,
  snsapiUserInfo,
} from '@/features/weixin/authorize';
import throttlePromise from '@/features/common/utils/throttlePromise';
import axios from '@/features/api/axios';
import { isWeixin } from '@/features/weixin/utils';
import useMeStore from '@/features/auth/useMeStoreAdapter';
import { frontEndUrl } from '@/features/auth/originGet';
import vconsole from '@/features/common/libs/vconsole';
import bindSuperior from '@/features/api/bindSuperior';
import loginLog from '@/features/api/loginLog';

export const weixinAuthorizeUserInfo = () => snsapiUserInfo({ state: 1 });

const checkAuth = async (isForceWeixinAuthorize = false) => {
  if (location.hash.includes('update')) return;
  if (isForceWeixinAuthorize) tokenStorage('');
  let token = tokenStorage();

  if (!token && !isWeixin && location.port) {
    token = prompt('开发环境中，请输入token');
    if (token) {
      tokenStorage(token);
      location.reload();
    }
    throw '';
  }

  if (!token || isForceWeixinAuthorize || hasCodeAndState) {
    let res = await weixinAuthorize();
    res = await codeToToken(res.code, res.state);

    token = tokenStorage(res.access_token);
  }

  const meStore = useMeStore();

  await Promise.all([meStore.fetch(), bindSuperior()]);

  if (!location.port && !urlToken) {
    const jumpUrl = frontEndUrl(meStore.me?.the_front, meStore.me?.user_type);
    if (jumpUrl) {
      location.replace(jumpUrl);
      throw '';
    }
  }

  if (meStore.me?.debug_mode) {
    vconsole();
  }

  if (!location.port) loginLog();

  return token;
};

export default throttlePromise(checkAuth);

const codeToToken = async (code, state) => {
  const res = await axios.get('/wx', {
    params: {
      code,
      silent: state,
    },
  });

  if (typeof res === 'string') return await weixinAuthorize();

  if (
    ['invalid code', 'code been used'].find((i) =>
      JSON.stringify(res).includes(i)
    )
  ) {
    return await weixinAuthorize();
  }

  if (+res.code === -1001) return await weixinAuthorizeUserInfo();

  if (!res.access_token) throw new Error(JSON.stringify(res));

  return res;
};
